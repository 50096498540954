cs.ns('app.ui.trait.root.businessservices')
app.ui.trait.root.businessservices.ctrl = cs.trait({
    protos: {

        setup () {
            this.base()

            this.subscribeDataService('login', (username, password, token, result) => {
                return result
            })

            this.subscribeDataService('access', (result) => {
                return result
            })

            this.subscribeDataService('readChannels', (result) => {
                app.dm.destroyAll('Channel')
                return _.map(result, obj => { return app.dm.create('Channel', obj) })
            })

            this.subscribeDataService('readContentList', (channel, result) => {
                return _.map(result, obj => {
                    const oldContent = app.dm.findById('Content', obj.hash)
                    if (oldContent) {
                        return app.dm.import('Content', oldContent, obj)
                    }
                    return app.dm.create('Content', obj)
                });
            })

            this.subscribeDataService('readUrlMap', (hash, result) => {
                return result;
            })
        }
    }
})